'use client';

import { ReactElement, useEffect, useId, useState } from 'react';
import styles from './H1.module.sass';

interface H1Props {
    className?: string;
    value?: string;
    isEditable?: boolean;
    allowMultiLine?: boolean;
    children?: ReactElement | ReactElement[] | string;
    onChange?: (newValue: string) => void;
    placeholder?: string;
    maxLength?: number;
    disabled?: boolean;
}

const H1: React.FC<H1Props> = ({
    className = '',
    value = '',
    isEditable = false,
    allowMultiLine = false,
    children,
    onChange,
    placeholder,
    maxLength,
    disabled = false,
}: H1Props) => {
    const [hasValue, setHasValue] = useState(!!value);
    const inputId = useId();

    useEffect(() => {
        setHasValue(!!value);
    }, [value]);

    if (isEditable) {
        return (
            <div className={`relative grid grid-cols-1 ${className}`}>
                {!!placeholder && (
                    <label
                        className={`${styles['h1__label']} ${
                            hasValue && styles['h1__label--filled']
                        }`}
                        htmlFor={inputId}
                    >
                        {placeholder}
                    </label>
                )}
                <input
                    id={inputId}
                    className={`${styles['h1__input']} text-3xl`}
                    onChange={(event) => {
                        onChange && onChange(event.target.value);
                        setHasValue(event.target.value.length > 0);
                    }}
                    value={value}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={disabled}
                />
            </div>
        );
    }

    return (
        <h1
            className={`${styles['h1']} ${
                allowMultiLine ? styles['h1--multiline'] : ''
            } text-3xl ${className}`}
        >
            {children}
        </h1>
    );
};

export default H1;
